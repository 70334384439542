<script setup>
const nuxtApp = useNuxtApp()

const { data, status, error } = await useLazyAsyncData('productmatrix', () => $fetch('/api/product/matrix'), {
    transform: (data) => {
        return {
            productMatrix: data.products.edges.map((edge) => edge.node),
            fetchedAt: new Date(Date.now()),
        }
    },
    getCachedData(key) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

        if (!data) {
            return
        }

        const expirationDate = new Date(data.fetchedAt)
        expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
        const isExpired = expirationDate.getTime() < Date.now()
        if (isExpired) {
            return
        }

        return data
    },
})

watchEffect(() => {
    if (error.value) {
        throw createError({
            statusCode: 500,
            message: error.value,
            fatal: false,
        })
    }
})
</script>

<template>
    <v-row id="productmatrix" class="mt-16 rounded justify-center">
        <v-col v-if="status === 'pending' || status === 'idle'" cols="12"><app-loader /></v-col>
        <template v-else>
            <v-col>
                <v-row class="d-flex flex-column">
                    <v-col cols="12" class="mt-4 pl-5 text-h5 font-weight-medium"> Our Favorites </v-col>
                    <v-col v-for="product in data.productMatrix.slice(0, 3)" :key="product.id" cols="12">
                        <product-card-small :product="product" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row class="d-flex flex-column">
                    <v-col cols="12" class="mt-4 pl-5 text-h5 font-weight-medium"> Top Trending </v-col>
                    <v-col v-for="product in data.productMatrix.slice(3, 6)" :key="product.id" cols="12">
                        <product-card-small :product="product" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row class="d-flex flex-column">
                    <v-col cols="12" class="mt-4 pl-5 text-h5 font-weight-medium"> New Releases </v-col>
                    <v-col v-for="product in data.productMatrix.slice(6)" :key="product.id" cols="12">
                        <product-card-small :product="product" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="mb-16 mt-10 d-flex justify-center">
                <v-theme-provider theme="sTLightTheme">
                    <app-button
                        :to="`/shop`"
                        :btn-text="`View All Products`"
                        :append-icon="`mdi-chevron-right`"
                        size="x-large"
                        aria-label="View all products"
                    />
                </v-theme-provider>
            </v-col>
        </template>
    </v-row>
</template>

<style scoped></style>
