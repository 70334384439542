<script setup>
//import { useDisplay } from 'vuetify'
//const { xs } = useDisplay()
const { formatUSD } = useFormatters()
const staticURLImg = useRuntimeConfig().public.staticURLImg

// Props for product
const props = defineProps({
    product: {
        type: Object,
        default: null,
    },
})

const currentPrice = computed(() => {
    if (props.product && props.product.productPrices.totalCount > 0) {
        return props.product.productPrices.edges[0].node.price
    } else {
        return props.product.price
    }
})
</script>

<template>
    <v-card flat class="st-box-shadow">
        <div class="d-flex flex-no-wrap">
            <nuxt-link :to="`/shop/${product.handle}`" :aria-label="`Explore ${product.name}`">
                <v-avatar class="ma-1 rounded elevation-1" size="128">
                    <v-img
                        id="cardImg"
                        :src="
                            product.productImages.totalCount > 1
                                ? staticURLImg + product.productImages.edges[1]?.node.source
                                : staticURLImg + product.productImages.edges[0]?.node.source
                        "
                        :alt="product.name"
                    >
                    </v-img>
                </v-avatar>
            </nuxt-link>
            <div class="pa-0 d-flex flex-column">
                <v-card-title
                    class="px-2 pb-0 text-wrap"
                    style="font-size: 1.1rem !important; font-weight: 600 !important"
                >
                    <!-- <nuxt-link :to="`/shop/${product.handle}`" class="text-decoration-none"> -->
                    {{ product.name }}
                    <!-- </nuxt-link> -->
                </v-card-title>
                <v-card-subtitle class="pl-2 pt-0 font-weight-medium text-caption hidden-md-and-down">
                    {{ product.tagline }}
                </v-card-subtitle>
                <v-spacer></v-spacer>
                <v-card-actions class="pl-2 font-weight-medium text-subtitle-1">
                    <span
                        :class="
                            currentPrice > 0 && currentPrice != product.price ? 'text-decoration-line-through' : null
                        "
                    >
                        {{ formatUSD(product.price) }}
                    </span>
                    <v-spacer></v-spacer>
                    <span
                        v-if="currentPrice > 0 && currentPrice != product.price"
                        class="px-2 bg-secondary rounded-pill"
                    >
                        {{ formatUSD(currentPrice) }}
                    </span>
                </v-card-actions>
                <!-- <app-rating :rating="parseFloat(product.productOption.rating)" class="pl-1 pt-4" /> -->
            </div>
        </div>
    </v-card>
</template>

<style scoped></style>
